/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import React from "react";
import MDXLayout from "/builds/thiagovilla/thiagovilla.com/src/templates/blog";
function _createMdxContent(props) {
  return React.createElement(React.Fragment);
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
